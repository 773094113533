<div
  style="background: #f2f7f7"
  class="text-gray-500 pb-2.5 pt-2.5 relative min-h-[50px]">
  <div class="flex justify-between items-center w-full">
    <button
      *ngIf="buttonBack"
      class="flex items-center text-softGray"
      (click)="actionBack()">
      <svg
        class="w-6 h-6 font-extrabold"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg">
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="3"
          d="M15 19l-7-7 7-7"></path>
      </svg>
      <span class="text-softGray text-2xl uppercase">
        {{ 'BACK' | translate }}</span
      >
    </button>

    <div
      *ngIf="title"
      class="absolute inset-0 flex items-end justify-center pointer-events-none">
      <span class="text-4xl font-normal uppercase pb-1 text-[#2F5973]">
        {{ title }}
      </span>
    </div>

    <div *ngIf="subtitle" class="flex flex-col items-start">
      <span class="text-2xl font-bold uppercase text-[#2F5973]">
        {{ name }}
      </span>
      <span class="text-lg uppercase mt-1 text-[#2f5975b3]">
        <span *ngIf="distribuidora">{{ distribuidora }}</span>
        <span class="text-[#2F5975] font-medium"> • {{ screen }} </span>
      </span>
    </div>
  </div>
</div>

<div
  class="border-t-2 opacity-[0.4] border-[#2F5E73] pb-2 -mx-4"
  [ngClass]="{ 'no-padding': paddingRow }"></div>
